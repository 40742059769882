import { useState } from 'react';
import useLatest from '@react-hook/latest';
import bytes from 'bytes';
import { uploadImage } from '../services/SocialAPI';
import { getRandomId } from '../utils/linkHelpers';
import { Attachment, Enviroment } from '../types';

export interface UploadingFile {
  id: string;
  file: File;
  progress: number;
}

interface UseFileUpload {
  allowedTypes: string[];
  maxFileSize: string;
  userToken: string;
  env?: Enviroment;
  onFileUploaded: (file: Attachment) => void;
  checkFileNameIsValid?: (file: File) => boolean;
}

const useFileUpload = ({
  allowedTypes,
  maxFileSize,
  userToken,
  env,
  onFileUploaded,
  checkFileNameIsValid,
}: UseFileUpload) => {
  const [uploadingFiles, setUploadingFiles] = useState<UploadingFile[]>([]);
  const [attachmentError, setAttachmentError] = useState<string>();
  const fileUploadHandler = useLatest(onFileUploaded);

  const handleFileSelect = (event: Event) => {
    if (!event.target) {
      return;
    }

    const inputEvent = event.target as HTMLInputElement;

    if (!inputEvent.files) {
      return;
    }

    const file = inputEvent.files[0];

    if (!allowedTypes.includes(file.type)) {
      setAttachmentError(
        'File type not supported. Please choose a different file.',
      );
      return;
    }

    if (file.size > (bytes(maxFileSize) || 0)) {
      setAttachmentError(`Your attachment cannot exceed ${maxFileSize}.`);
      return;
    }

    if (checkFileNameIsValid && !checkFileNameIsValid(file)) {
      setAttachmentError(
        `The attachment with the name "${file.name}" is already uploaded.`,
      );
      return;
    }

    setAttachmentError(undefined);

    const uploadingFile: UploadingFile = {
      id: getRandomId(),
      progress: 0,
      file,
    };
    setUploadingFiles((currentUploadingFiles) => [
      ...currentUploadingFiles,
      uploadingFile,
    ]);

    uploadImage(userToken, file, env, '', (progress) => {
      setUploadingFiles((currentUploadingFiles) =>
        currentUploadingFiles.map((item) =>
          uploadingFile.file === file ? { ...item, progress } : item,
        ),
      );
    })
      .then((data) => {
        fileUploadHandler.current?.({
          url: data.file,
          name: file.name,
          type: file.type,
        });
      })
      .catch(() => {
        setAttachmentError(
          'File upload failed due to server error.  Please try again.',
        );
      })
      .finally(() => {
        setUploadingFiles((currentUploadingFiles) =>
          currentUploadingFiles.filter((item) => item.file !== file),
        );
      });
  };

  const pickFile = () => {
    const inputElement = document.createElement('input');
    inputElement.type = 'file';
    inputElement.accept = allowedTypes.join(',');
    inputElement.addEventListener('change', handleFileSelect);
    inputElement.addEventListener('blur', handleFileSelect);
    inputElement.dispatchEvent(new MouseEvent('click'));
  };

  return {
    pickFile,
    uploadingFiles,
    attachmentError,
    isFileUploading: !!uploadingFiles.length,
  };
};

export default useFileUpload;
